import {dateToTimestamp, timestampToLocalString} from "../utilities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone, faPhoneSlash} from "@fortawesome/free-solid-svg-icons";
import {JOURNAL_PROG_CALL_RESPONSE_HAS_NOT_REPLIED, JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED} from "../api/wimhome-api";
import React from "react";
import {useTranslation} from "react-i18next";

export function ProgrammedCallEntry(props) {
  const {
    entry,
    idx,
    setProgrammedCall
  } = props;
  const {t} = useTranslation();

  return (
      <div className={"programmed-call-row" + (entry?.caseData?.nextCall < dateToTimestamp(new Date()) ? " expired" : " valid") + (idx % 2 ? " dark" : " light")}>
        <div className={"programmed-call-icon"}>
          {(entry?.caseData?.result === JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED) &&
              <FontAwesomeIcon icon={faPhone}/>}
          {(entry?.caseData?.result === JOURNAL_PROG_CALL_RESPONSE_HAS_NOT_REPLIED) &&
              <FontAwesomeIcon icon={faPhoneSlash}/>}
        </div>
        <div className={"programmed-call-content"}>
          <span
              className="programmed-call-timestamp">{`${timestampToLocalString(entry?.caseData?.nextCall)}`}</span>
        </div>
        <div>
          <span className="programmed-call-installation">{`${entry.installationTitle}`}</span>
        </div>
        <div>
          <span
              className="programmed-call-last-call">{`(${t("programmedCalls.lastCall")} ${timestampToLocalString(entry.lastUpdatedAt)})`}</span>
        </div>
        <div>
          {entry.title !== t('journal.teleAssistance') && <span className="respect-whitespace">{`${entry.title} `}</span>}
          <span className="respect-whitespace">{`${entry.instCaseEntries[0].description}`}</span>
        </div>
        <div className={"programmed-call-buttons"}>
          {entry?.caseData?.nextCall <= dateToTimestamp(new Date()) &&
              < button
                  className='cbutton'
                  onClick={(e) => {
                    e.preventDefault()
                    setProgrammedCall({
                      "installationSerial": entry.installationSerial,
                      "installationTitle": entry.installationTitle,
                      "lastCall": entry
                    })
                  }}
                  type={'button'}
              >{t('programmedCalls.buttonCall')}</button>}
        </div>
        <div></div>
      </div>
  )
}
