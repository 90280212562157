import React, {Fragment, useEffect, useState} from "react";
import {InstallationsContext} from "./App";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faMobileAlt,
  faPhone, faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "react-responsive-modal";
import { it, enUS, enGB, de, fr } from 'date-fns/esm/locale';
import {format as formatDate} from "date-fns/esm";
import {timestampToDate, timestampToLocalString} from "../utilities";
import ResourceLink from "./ResourceLink";
import Select from "react-select";
import {confirmAlert} from "react-confirm-alert";
import {
  JOURNAL_PROG_CALL_RESPONSE_HAS_NOT_REPLIED,
  JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED
} from "../api/wimhome-api";

//Used by date-fns/esm format
const locales = { it, de, fr, enGB, enUS };

export function ProgrammedCallCallInterface(props) {
  const {
    installationSerial,
    installationTitle,
    lastCall,
    saveCall,
    abort
  } = props;
  const {t, i18n} = useTranslation();
  const {
    directInstGuestLoad,
  } = React.useContext(InstallationsContext);

  const [newCallDetail, setNewCallDetail] = useState({description: "", result: ""})
  const [guest, setGuest] = useState([]);

  const PHONE_NUMBER_TYPE_OPTIONS = [
    { value: 'land', label: t('guests.landline') },
    { value: 'cell', label: t('guests.cellular') },
    { value: 'viva', label: t('guests.vivaVoice') },
  ];

  function cleanupPhoneNumber(number) {
    if(number == null || number.length === 0)
      return number;
    let cleanNumber = "";
    if(number.substring(0,1) === "+")
      cleanNumber = "00" + number.substring(1).replace(/[\s\-()]/g, '');
    else
      cleanNumber = number.replace(/[\s\-()]/g, '');
    return cleanNumber;
  }

  function getSexLabel(sex) {
    if (sex === 'M') {
      return t('guests.male');
    } else if (sex === 'F') {
      return t('guests.female');
    } else {
      return t('guests.unknown');
    }
  }
  
  useEffect(async () => {
    if(installationSerial == null) {
      return
    }
    const guests = await directInstGuestLoad(installationSerial);
    if(guests == null || guests.length === 0) {
      confirmAlert({
        title: `${t('programmedCalls.errorTitle')}`,
        message: `${t('programmedCalls.cannotLoadGuest')}`,
        buttons: [
          {
            label: t('OK'),
            onClick: () => { abort() }
          }
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayClassName: "overlay-confirm-alert-over-all"
      })
    } else {
      setGuest(guests[0]);
    }
  }, [installationSerial])

  function close() {
    abort();
    setGuest(null);
  }
  
  return (
      <Modal
          classNames={{
            // overlay: 'customOverlay',
            modal: 'fullWidthModal',
          }}
          onClose={close}
          open={installationSerial != null}
      >
        <>
          <h1>{t('programmedCalls.titleCall')}</h1>
          <hr/>
          <p>
            <b>{`${t('programmedCalls.installationTitle')}:`}</b> <span className="respect-whitespace">{installationTitle}</span>
          </p>
          <p>
            <b>{`${t('guests.name')}:`}</b> {`${guest.name}`}
          </p>
          <p>
            <b>{`${t('guests.surname')}:`}</b> {`${guest.surname}`}
          </p>
          {typeof guest.sex !== 'undefined' && (
              <p>
                <b>{`${t('guests.sex')}:`}</b> {`${getSexLabel(guest.sex)}`}
              </p>
          )}
          {typeof guest.birthDate !== 'undefined' && (
              <p>
                <b>{`${t('guests.birthDate')}:`}</b>{' '}
                {`${formatDate(timestampToDate(guest.birthDate), 'P', {locale: locales[i18n.language]})}`}
              </p>
          )}
          {typeof guest.fiscalIdentifier !== 'undefined' && guest.fiscalIdentifierType?.id != null && (
              <p>
                <b>{`${guest.fiscalIdentifierType.name}:`}</b> {`${guest.fiscalIdentifier}`}
              </p>
          )}
          {typeof guest.summary !== 'undefined' && (
              <p>
                <b>{`${t('guests.summary')}:`}</b> {`${guest.summary}`}
              </p>
          )}
          {typeof guest.note !== 'undefined' && (
              <p>
                <b>{`${t('guests.note')}:`}</b> {`${guest.note ?? '-'}`}
              </p>
          )}
          {typeof guest.phone1Info !== 'undefined' && (
              <p>
                {guest.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                {guest.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                {guest.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                <a className='phone-link'
                   href={`${guest.phone1Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guest.phone1Info.number)}`}>{`${guest.phone1Info.number}`}</a> {`${guest.phone1Info.desc}`}
              </p>
          )}
          {typeof guest.phone2Info !== 'undefined' && (
              <p>
                {guest.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                {guest.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                {guest.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                <a className='phone-link'
                   href={`${guest.phone2Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guest.phone2Info.number)}`}>{`${guest.phone2Info.number}`}</a> {`${guest.phone2Info.desc}`}
              </p>
          )}
          {typeof guest.phone3Info !== 'undefined' && (
              <p>
                {guest.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                {guest.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                {guest.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                <a className='phone-link'
                   href={`${guest.phone3Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guest.phone3Info.number)}`}>{`${guest.phone3Info.number}`}</a> {`${guest.phone3Info.desc}`}
              </p>
          )}
          {typeof guest.phone4Info !== 'undefined' && (
              <p>
                {guest.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                {guest.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                {guest.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                <a className='phone-link'
                   href={`${guest.phone4Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guest.phone4Info.number)}`}>{`${guest.phone4Info.number}`}</a> {`${guest.phone4Info.desc}`}
              </p>
          )}
          {typeof guest.phone5Info !== 'undefined' && (
              <p>
                {guest.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                {guest.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                {guest.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                <a className='phone-link'
                   href={`${guest.phone5Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guest.phone5Info.number)}`}>{`${guest.phone5Info.number}`}</a> {`${guest.phone5Info.desc}`}
              </p>
          )}
          {guest.links && (
              <div className='guest-links-section'>
                {guest.links.map((link) => {
                  return <ResourceLink key={link.url} className='installation-desc-link' linkState={link}/>;
                })}
              </div>
          )}
          {lastCall && (
              <div>
                <hr/>
                <p><span>{t('programmedCalls.lastCall')}</span> {`${timestampToLocalString(lastCall.lastUpdatedAt)}`}
                  <span> {lastCall?.caseData?.result === JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED && t('journal.hasReplied')}
                    {lastCall?.caseData?.result === JOURNAL_PROG_CALL_RESPONSE_HAS_NOT_REPLIED && t('journal.hasNotReplied')}
                </span> {lastCall?.instCaseEntries[0].description &&
                      <span className="respect-whitespace">({lastCall?.instCaseEntries[0].description})</span>}
                </p>
                <hr/>
              </div>
          )}
          <div className="programmed-calls-interface">
            <label htmlFor={"journal-result-type"} style={{display: 'flex'}}>
              <b style={{display: 'inline-block', marginRight: '0.5em'}}>{`${t('journal.result')}:`}</b>
              <Select
                  className='dropdown-select'
                  classNamePrefix="select"
                  isSearchable={false}

                  name="journal-result-type"
                  onChange={(option) => {
                    const value = option.value;
                    setNewCallDetail(newCallDetailCur => ({
                      ...newCallDetailCur,
                      result: value
                    }));
                  }}
                  options={[
                    {value: JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED, label: t('journal.hasReplied')},
                    {value: JOURNAL_PROG_CALL_RESPONSE_HAS_NOT_REPLIED, label: t('journal.hasNotReplied')},
                  ]}
                  placeholder={t('journal.select')}
                  value={((j) => {
                    switch (j.result) {
                      case JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED:
                        return {value: JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED, label: `${t('journal.hasReplied')}`}
                      case JOURNAL_PROG_CALL_RESPONSE_HAS_NOT_REPLIED:
                        return {value: JOURNAL_PROG_CALL_RESPONSE_HAS_NOT_REPLIED, label: `${t('journal.hasNotReplied')}`}
                    }
                  })(newCallDetail)}
              />
            </label>
          </div>
          <div className="programmed-calls-interface">
            <label htmlFor="journalEntryDescription">
              <b>{`${t('journal.journalEntryDescription')}:`}</b>
              <textarea
                  defaultValue={`${newCallDetail.description}`}
                  name="journalEntryDescription"
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setNewCallDetail(newCallDetailCur => ({...newCallDetailCur, description: newValue}));
                  }}
              />
            </label>
          </div>
          <div className='guest-details-modify-buttons'>
            <button
                className='cbutton'
                onClick={() => {
                  setNewCallDetail(newCallDetailCur => ({...newCallDetailCur, description: "", result: ""}));
                  abort()
                }}
                type='button'
            >
              {t('Cancel')}
            </button>
            <button
                className='cbutton'
                onClick={() => {
                  if(newCallDetail.result === "") {
                    confirmAlert({
                      title: `${t('Error')}`,
                      message: `${t('journal.missingResult')}`,
                      buttons: [
                        {
                          label: `${t('OK')}`,
                        },
                      ],
                      closeOnEscape: false,
                      closeOnClickOutside: false,
                      overlayClassName: "overlay-confirm-alert-over-all"
                    });
                    return;
                  }
                  saveCall(installationSerial, lastCall, newCallDetail.description, newCallDetail.result)
                }}
                type='button'
            >
              {t('Save')}
            </button>
          </div>
        </>
      </Modal>
  );
}