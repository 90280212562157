export const SITE_LINKS = {
  LOGIN: '/',
  INSTALLATIONS: '/installations',
  STATUS: '/status',
  LOG: '/log',
  ALERT_DETAIL: '/alert_detail',
  GRAPHS: '/graphs',
  TRENDS: '/trends',
  TECH: '/tech',
  REMINDERS_ADMIN: '/reminders_admin',
  REMINDER_ACTIONS_ADMIN: '/reminder_actions_admin',
  REPORTS: '/reports',
  COOKIES: '/cookies',
  PRIVACY: '/privacy',
};

// export const LOCAL_VERSION = true;
// export const IS_CB_H24 = true;
// export const USE_DEBUG_SERVER = false;

export const LOCAL_VERSION = process.env.REACT_APP_IS_LOCAL_VERSION === 'true';
export const REACT_APP_NEW_MQTT_AUTH = process.env.REACT_APP_NEW_MQTT_AUTH === 'true';

export const LOCAL_STORAGE_KEYS = {
  LAST_USER: process.env.REACT_APP_LOCAL_STORAGE_KEYS_PREFIX + '.currentUser',
  INSTALLATIONS: process.env.REACT_APP_LOCAL_STORAGE_KEYS_PREFIX + '.installations',
  SELECTED_INST: process.env.REACT_APP_LOCAL_STORAGE_KEYS_PREFIX + '.selectedInst',
  GUESTS: process.env.REACT_APP_LOCAL_STORAGE_KEYS_PREFIX + '.guests',
  CONTACTS: process.env.REACT_APP_LOCAL_STORAGE_KEYS_PREFIX + '.contacts',
  SERVICES: process.env.REACT_APP_LOCAL_STORAGE_KEYS_PREFIX + '.services',
  REPORTS: process.env.REACT_APP_LOCAL_STORAGE_KEYS_PREFIX + '.reports',
  JWT: process.env.REACT_APP_LOCAL_STORAGE_KEYS_PREFIX + '.jwt',
  AUTH_TOKEN_TIMESTAMP: process.env.REACT_APP_LOCAL_STORAGE_KEYS_PREFIX + '.authTokenTimestamp',
  ACK_ALERTS: process.env.REACT_APP_LOCAL_STORAGE_KEYS_PREFIX + '.ackAlerts',
  ALERTS_SOUND_INFO: process.env.REACT_APP_LOCAL_STORAGE_KEYS_PREFIX + '.alertsSoundInfo',
  ALERT_CODES: process.env.REACT_APP_LOCAL_STORAGE_KEYS_PREFIX + '.alertCodes',
};

export const DEFAULT_COLORS = {
  NORMAL: 'rgba(255,255,255,1)',
  ACTIVE: 'rgba(117, 172, 101, 1)',
  WARNING: 'rgba(243, 156, 18, 1)',
  ERROR: 'rgba(217, 83, 79, 1)',
  LINE_GRAPH_COLOR: 'rgba(61,109,195, 1)',
};

export const EXTERNAL_LINKS = {
  PLAY_STORE_APP: 'https://play.google.com/store/apps/details?id=it.itcares.wimonitor',
  APP_STORE_APP: 'itms://itunes.apple.com/app/wimhome/id1495717567',
};
