import {
  faBullhorn,
  faClipboardList,
  faCog,
  faConciergeBell,
  faExclamationTriangle,
  faHammer,
  faHandPaper, faHeadset,
  faHourglassEnd,
  faHourglassHalf, faLink,
  faLock,
  faLockOpen,
  faMagic,
  faMapMarkedAlt, faPhone, faPhoneSlash,
  faStar,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Fragment } from 'react';
import {
  convertFontAwesomeIconName,
  not_null_or_undefined,
  timestampToLocalString,
  timestampToLocalStringO
} from '../utilities';
import { useTranslation } from "react-i18next";
import ResourceLink from "./ResourceLink";
import {
  CASE_DESCRIPTION_TYPE_ALARM,
  CASE_DESCRIPTION_TYPE_CALL,
  CASE_DESCRIPTION_TYPE_JOURNAL,
  CASE_DESCRIPTION_TYPE_PROG_CALL,
  CASE_DESCRIPTION_TYPE_TECH,
  CASE_DESCRIPTION_TYPE_WARNING,
  CASE_ENTRY_TYPE_CLOSE,
  CASE_ENTRY_TYPE_EXPIRE, CASE_ENTRY_TYPE_JOURNAL,
  CASE_ENTRY_TYPE_OPEN, CASE_ENTRY_TYPE_PROG_CALL, CASE_ENTRY_TYPE_REOPEN,
  CASE_ENTRY_TYPE_TAKE, JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED
} from "../api/wimhome-api";

export default function CaseTableRow({caseInfo, deviceInfo, deviceInfos, inhibitHandler, takeHandler, reopenHandler, openJournalHandler, installationOffline}) {
  const {t, i18n} = useTranslation();
  const IMAGE_BASE_URL = process.env.REACT_APP_WIMHOME_WEB_SERVER_URL;

  function decodeCaseIcon(type) {
    switch(type) {
      case CASE_DESCRIPTION_TYPE_ALARM:
        return (
          <FontAwesomeIcon icon={faExclamationTriangle} />
        );

      case CASE_DESCRIPTION_TYPE_WARNING:
        return (
          <FontAwesomeIcon icon={faBullhorn} />
        );

      case CASE_DESCRIPTION_TYPE_TECH:
        return (
          <FontAwesomeIcon icon={faHammer} />
        );

      case CASE_DESCRIPTION_TYPE_CALL:
        return (
          <FontAwesomeIcon icon={faConciergeBell} />
        );
  
      case CASE_DESCRIPTION_TYPE_JOURNAL:
        return (
          <FontAwesomeIcon icon={faClipboardList} />
        );

      case CASE_DESCRIPTION_TYPE_PROG_CALL:
        return (
            <FontAwesomeIcon icon={faHeadset} />
        );
  
      default:
        break;
    }
  }

  function decodeCaseEntryIcon(type) {
    switch(type) {
      case CASE_ENTRY_TYPE_OPEN:
        return (
          <FontAwesomeIcon icon={faStar} />
        );

      case CASE_ENTRY_TYPE_TAKE:
        return (
          <FontAwesomeIcon icon={faHourglassHalf} />
        );

      case CASE_ENTRY_TYPE_CLOSE:
        return (
          <FontAwesomeIcon icon={faHandPaper} />
        );

      case CASE_ENTRY_TYPE_EXPIRE:
        return (
          <FontAwesomeIcon icon={faHourglassEnd} />
        );
  
      case CASE_ENTRY_TYPE_REOPEN:
        return (
          <FontAwesomeIcon icon={faLockOpen} />
        );
    
      case CASE_ENTRY_TYPE_JOURNAL:
        return (
          <FontAwesomeIcon icon={faClipboardList} />
        );

      case CASE_ENTRY_TYPE_PROG_CALL:
        return (
            <FontAwesomeIcon icon={faPhone} />
        );

      default:
        break;
    }
  }

  function decodeCaseClass(type, isInhibited, isClosed, isJournal) {
    if(isInhibited)
      return 'inhibited-bg';

    if(isClosed)
      return 'case-closed-bg';

    if(isJournal)
      return 'journal-bg';

    switch(type) {
      case 1:
        return 'alarm-bg';

      case 2:
        return 'warning-bg';

      case 3:
        return 'warning-bg';

      case 4:
        return 'warning-bg';
  
      default:
        break;
    }
  }

  function decodeCaseEntryAuthor(entry)
  {
    if(entry == null)
      return null;

    if((entry.userFirstName == null || entry.userFirstName === '-') && 
      (entry.userLastName == null || entry.userLastName === '-') && 
      (entry.userOrganization == null || entry.userOrganization === '-')) {
      return (
        <>
          <FontAwesomeIcon 
            icon={convertFontAwesomeIconName(entry?.userIconUrl) ?? faCog} 
          /> {`${t('alerts.system')}`}
        </>
      );
    }
    return (
      <>
        <FontAwesomeIcon 
          icon={convertFontAwesomeIconName(entry?.userIconUrl) ?? faUser} 
        /> {`${entry?.userFirstName ?? ''} ${entry?.userLastName ?? ''}${entry?.userOrganization ? ', ' + entry.userOrganization : ''}`}
      </>
    );
  }

  function generateCaseOptionalLinks(deviceInfo, caseData, isClosed)
  {
    if(isClosed)
      return null;
    let elements = [];
    let counter = 0;
    if(deviceInfo) {
      if(caseData) {
        // console.log(deviceInfo.customAttrib);
        for(const attrib in caseData) {
          if(attrib === 'link') {
            elements.push(
                <ResourceLink
                    key={counter++}
                    className="case-desc-link"
                    icon={faLink}
                    linkState={{
                      url: caseData[attrib].url,
                      linkName: `${caseData[attrib].name}`,
                      description: `${caseData[attrib].name}`
                    }}
                />
            )
          } else if (attrib === 'dynamicPosition') {
            const referencedDeviceInfo = deviceInfos.has(caseData[attrib].deviceId) ? deviceInfos.get(caseData[attrib].deviceId) : null
            if(referencedDeviceInfo) {
              const position = JSON.parse(referencedDeviceInfo.value);
              // console.log(position)
              elements.push(
                  <ResourceLink
                      key={counter++}
                      className="case-desc-position-link"
                      icon={faMapMarkedAlt}
                      linkState={{
                        url: `https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`,
                        linkName: `${t('updated')}: ${timestampToLocalStringO(referencedDeviceInfo.lastStateChange, i18n.language,
                            {day: '2-digit', month: '2-digit', year: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit"})}, ${t('accuracy')}: ${position.accuracy} m`,
                        description: `${t('position')}`
                      }}
                  />
              )
            }
          }
        }
      }

      return (<>
        {elements.map((el) => {
          return (<div>{el}</div>);
        })}
      </>);
    }
  }

  function generateCaseDevIcon(deviceInfo, caseInfo) {
    if (deviceInfo != null && not_null_or_undefined(deviceInfo.iconURL))
      return <img alt={deviceInfo.name} src={IMAGE_BASE_URL + deviceInfo.iconURL}/>

    if (caseInfo?.caseData != null && caseInfo.caseData.result != null) {
      if(caseInfo.caseData.result === JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED) {
        return <FontAwesomeIcon icon={faPhone}/>
      } else {
        return <FontAwesomeIcon icon={faPhoneSlash}/>
      }
    }

    return <FontAwesomeIcon icon={faConciergeBell}/>
  }

  function generateCaseEntryOptionalLinks(entryData) {
    let elements = [];
    let counter = 0;
    if(entryData) {
      // console.log(entryData);
      for(const attrib in entryData) {
        if(attrib === 'link') {
          elements.push(
              <ResourceLink
                  key={counter++}
                  className="case-desc-link"
                  icon={faLink}
                  linkState={{
                    url: entryData[attrib].href,
                    linkName: `${entryData[attrib].name[i18n.language]}`,
                    description: `${entryData[attrib].name[i18n.language]}`
                  }}
              />
          )
        } else if (attrib === 'position') {
          const position = entryData[attrib];
          // console.log(position)
          elements.push(
              <ResourceLink
                  key={counter++}
                  className="case-desc-position-link"
                  icon={faMapMarkedAlt}
                  linkState={{
                    url: `https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`,
                    linkName: `${t('updated')}: ${timestampToLocalStringO(position.timestamp, i18n.language,
                        {day: '2-digit', month: '2-digit', year: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit"})}, ${t('accuracy')}: ${position.accuracy} m`,
                    description: `${t('position')}`
                  }}
              />
          )
        }
      }

      return (<>
        {elements.map((el) => {
          return (<div>{el}</div>);
        })}
      </>);
    }
  }

  if (caseInfo == null)
    return null;

  return (
    <div className={`case-table-row ${decodeCaseClass(caseInfo.type, caseInfo.lastState === 2, caseInfo.lastState === 0, caseInfo.lastState === 5)}`} >
      <div className="case-table-row-info">
        <div className="case-info">
          <span className="case-dev-icon">
            {generateCaseDevIcon(deviceInfo, caseInfo)}
          </span>
          <span className="case-title">
            {caseInfo.title}
          </span>
          <span className="case-desc">
            {caseInfo.description}
          </span>
          <span className="case-optional-links">
            {/*{generateCaseOptionalLinks(deviceInfo, caseInfo.lastState === 0)}*/}
            {generateCaseOptionalLinks(deviceInfo, caseInfo.caseData, caseInfo.lastState === 0)}
          </span>
        </div>
        
        <div className="case-icon">
          {decodeCaseIcon(caseInfo.type)}
        </div>

        {caseInfo?.instCaseEntries?.map?.((entry, idx) => {
          return (
            <Fragment key={entry.id}>
              <div className={"case-entry-desc-row" + (entry.entryType === 1 ? " case-begin-entry" : "") + (idx % 2 ? " dark" : " light")}>
                <span className="case-entry-desk-icon">{decodeCaseEntryIcon(entry.entryType)}</span>
                <span className="case-entry-timestamp">{`${timestampToLocalString(entry.dateTimeUtc)}`}</span>
                <span className="respect-whitespace">{`${entry.description ? entry.description : (caseInfo.caseData?.result ? (caseInfo.caseData?.result === JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED ? t('journal.hasReplied') : t('journal.hasNotReplied')) : "")}`}</span>
                {generateCaseEntryOptionalLinks(entry.entryData)}
              </div>
              <div className={"case-entry-author-row" + (idx % 2 ? " dark" : " light")}>
                {decodeCaseEntryAuthor(entry)}
              </div>
            </Fragment>
          );
        })}
      </div>
      {(caseInfo?.type !== CASE_DESCRIPTION_TYPE_PROG_CALL) && ((caseInfo?.lastState === 1) || (caseInfo?.lastState === 2)|| (caseInfo?.lastState === 3) || (caseInfo?.lastState === 4)) &&
          (caseInfo?.canBeInhibited !== false) && (
        <div className={"case-inhibition-icon " + (installationOffline ? "disabled" : "")}
             onClick={() => { if(installationOffline) { return alert(`${t('alertPageDetail.cannotExecuteWhileDisconnected')}`); } inhibitHandler(caseInfo)}}>
          <FontAwesomeIcon icon={faHourglassHalf} />
        </div>
      )}
      {((caseInfo?.type !== CASE_DESCRIPTION_TYPE_PROG_CALL) && (caseInfo?.lastState !== 0) && (caseInfo?.lastState !== 5)) && (caseInfo?.selfClosing !== true) && (
        <div className={"case-closing-icon " + (installationOffline ? "disabled" : "")}
             onClick={() => { if(installationOffline) { return alert(`${t('alertPageDetail.cannotExecuteWhileDisconnected')}`); } takeHandler(caseInfo)}}>
          <FontAwesomeIcon icon={faHandPaper} />
        </div>
      )}
      {(caseInfo?.lastState === 0) && (
        <div className={"case-closing-icon " + (installationOffline ? "disabled" : "")}
             onClick={() => { if(installationOffline) { return alert(`${t('alertPageDetail.cannotExecuteWhileDisconnected')}`); } reopenHandler(caseInfo)}}>
          <FontAwesomeIcon icon={faLock} />
        </div>
      )}
      {(caseInfo?.lastState === 5) && (
        <div className={"case-closing-icon " + (installationOffline ? "disabled" : "")}
             onClick={() => { if(installationOffline) { return alert(`${t('alertPageDetail.cannotExecuteWhileDisconnected')}`); } openJournalHandler(caseInfo)}}>
          <FontAwesomeIcon icon={faMagic} />
        </div>
      )}
    </div>
  );
}
