import React from 'react';
import { useTranslation } from "react-i18next";
import { format as formatDate } from 'date-fns/esm';
import { it, enUS, enGB, de, fr } from 'date-fns/esm/locale';
import {timestampToDate, colourNameToHex, hexToRgb, not_null_or_undefined} from '../utilities';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faConciergeBell, faPhone, faPhoneSlash} from "@fortawesome/free-solid-svg-icons";
import {JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED} from "../api/wimhome-api";

//Used by date-fns/esm format 
const locales = {it, de, fr, enGB, enUS};

export default function JournalEntry(props) {
  const IMAGE_BASE_URL = process.env.REACT_APP_WIMHOME_WEB_SERVER_URL;
  const {t, i18n} = useTranslation(); 

  const {
    dateTime, 
    authorName, 
    authorSurname, 
    authorOrg, 
    authorColor,
    // authorIcon,
    nextCall,
    title, 
    caseDesc,
    entry,
    deviceInfo,
    progCallHasReply
  } = props;

  const bgColRGB = hexToRgb(colourNameToHex(authorColor ? authorColor : '#AAA'));

  let author = `${authorName ? authorName : ''}${(authorName && authorSurname) ? ' ' : ''}${authorSurname ? authorSurname : ''}${(authorName || authorSurname) ? ', ' : ''}${authorOrg ? authorOrg : ''}`;
  if((authorName == null || authorName === '-') && (authorSurname == null || authorSurname === '-') && (authorOrg == null || authorOrg === '-')) {
      author = `${t('alerts.system')}`;
  }

  function generateCaseDevIcon() {
    if (deviceInfo != null && not_null_or_undefined(deviceInfo.iconURL))
      return <img alt={deviceInfo.name} src={IMAGE_BASE_URL + deviceInfo.iconURL}/>

    if (progCallHasReply != null) {
      if(progCallHasReply === JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED) {
        return <FontAwesomeIcon icon={faPhone}/>
      } else {
        return <FontAwesomeIcon icon={faPhoneSlash}/>
      }
    }

    return <FontAwesomeIcon icon={faConciergeBell}/>
  }

  return (
    <div className="journal-entry" style={{background: `rgb(${bgColRGB.r}, ${bgColRGB.g}, ${bgColRGB.b}, 0.1)`}}>
      <div className="journal-entry-header">
        <span className="journal-entry-author">
          {`${t('journal.author')}: `}<b>{author}</b>
        </span>
        <span className="journal-entry-datetime">{`${t('journal.datetime')}: ${dateTime ? formatDate(timestampToDate(dateTime), "Ppp", {locale: locales[i18n.language]}) : '-'}`}</span>
      </div>
      <div className="journal-entry-body">
        <div style={{display: "flex", marginTop: "0.5em"}}>
          <div><span className="journal-entry-icon">{generateCaseDevIcon(caseDesc)}</span></div>
          {(caseDesc !== null) && (
              <details>
                <summary>
                  <span className="journal-entry-title">{title}</span>
                </summary>
                <span className="journal-entry-desc respect-whitespace">{caseDesc}</span>
              </details>
          )}
          {(caseDesc === null) && (
              <>
                <span className="journal-entry-title">{title}</span><br/>
              </>
          )}
        </div>
        <>
          <span className="journal-entry-text respect-whitespace">{entry}</span>
          {(nextCall) && (
              < span className="journal-entry-datetime">{`${t('journal.nextExecution')}: ${dateTime ? formatDate(timestampToDate(nextCall), "Ppp", {locale: locales[i18n.language]}) : '-'}`}</span>)}
        </>
      </div>
    </div>
  )
}
